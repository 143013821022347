import React from "react";
import _ from "lodash";

import { withPrefix, markdownify } from "../utils";
import CtaButtons from "./CtaButtons";
import CarouselBrand from "./carouselBrand";

export default class SectionContent extends React.Component {
  render() {
    let section = _.get(this.props, "section", null);
    return (
      <>
        <section
          id={_.get(section, "section_id", null)}
          className={
            "block text-block bg-" +
            _.get(section, "background", null) +
            " outer"
          }
        >
          <div className="inner">
            <div className="grid">
              {_.get(section, "image", null) && (
                <div className="cell block-preview">
                  <img
                    src={withPrefix(_.get(section, "image", null))}
                    alt={_.get(section, "image_alt", null)}
                  />
                </div>
              )}
              <div className="cell block-content">
                {_.get(section, "title", null) && (
                  <h2 className="block-title underline">
                    {_.get(section, "title", null)}
                  </h2>
                )}
                <div className="block-copy">
                  {markdownify(_.get(section, "content", null))}
                </div>
                {_.get(section, "actions", null) && (
                  <p className="block-buttons">
                    <CtaButtons
                      {...this.props}
                      actions={_.get(section, "actions", null)}
                    />
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
        <section
          id={_.get(section, "section_id", null)}
          className={
            "block text-block bg-" +
            _.get(section, "background", null) +
            " outer"
          }
          style={{ paddingBottom: 16, paddingTop: 48 }}
        >
          <div className="inner">
            {_.get(section, "title", null) && (
              <h2
                className="block-title underline"
                style={{ marginLeft: "auto" }}
              >
                {"Nos partenaires"}
              </h2>
            )}
            <p className="">
              Nous sommes honoré d'avoir la confiance des entreprises les plus
              talentueuses.
            </p>
          </div>
        </section>

        <CarouselBrand />
      </>
    );
  }
}
